import React from 'react'
import AboutHeroSection from './AboutHeroSection'
import FooterImg from '../../assets/footerimg.png';
import FooterImage from '../../footer_top_image';
import AboutusSection3 from './AboutusSection3';
import ContactForm from '../Franchise/ContactForm'

function About() {
  return (
    <>
        <AboutHeroSection />
        <AboutusSection3 />
        <ContactForm />
        <FooterImage dfimage={FooterImg} />
        
    </>
  )
}

export default About