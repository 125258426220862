import React from 'react'

const footer_top_image = (props) => {
  return (
    
         <div className='img_container'>
            <img className='desktop' src={props.dfimage}/>
            <img className='mobile' src={props.mfimage}/>
        </div>
     )
}

export default footer_top_image;
