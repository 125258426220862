import React from 'react';
import Cata_card from './what_we_have_card';

import Cata_img1 from '../../assets/whatWeHave1.png';
import Cata_img2 from '../../assets/whatWeHave2.png';
import Cata_img3 from '../../assets/whatWeHave3.png';
import Cata_img4 from '../../assets/whatWeHave4.png';
import Cata_img5 from '../../assets/whatWeHave5.png';
import Cata_img6 from '../../assets/whatWeHave6.png';
import Cata_img7 from '../../assets/whatWeHave7.png';

const what_we_have = () => {
  return (
    <div className='what_we_have_container'>
        <h1>WHAT WE HAVE</h1>
        <div className='card_container'>
        <Cata_card img ={Cata_img1} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img2} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img3} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img4} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img5} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img6} name="combos" price="Starting at `209"/>
        <Cata_card img ={Cata_img7} name="combos" price="Starting at `209"/>
        </div>
        
    </div>
  )
}

export default what_we_have;