import React from 'react'
import HomepageCtaDesktop from '../../assets/sauce_banner_desktop.png';
import HomepageCtaMobile from '../../assets/sauce_banner_mobile.png';

function HomepageCta() {
  return (
    <>
        <div className='bannerCta'>
            <img src={HomepageCtaDesktop} className="desktopVisible" />
            <img src={HomepageCtaMobile} className="mobileVisible" />
        </div>
    </>
  )
}

export default HomepageCta