import React from 'react'

function franchise_model_operated() {
  return (
    <div className='franchise_model_operated'>
    <div className='row'>
        <div className='franchise_model_operated_heading'>
            <h4>Operating Model: FOFO</h4>
            <h5>Franchisee Owned Franchisee Operated</h5>
        </div>
        
            <div className='franchise_model_operated_content'>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Store Size: 200 to 400 sq ft.
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Store will be sourced by Company's business development team in coordination with the Franchisee.
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  This is a technically skilled business and hence the company shall deploy its own resources such as
Operations Managers , Store Managers, Area Managers, etc.
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Regular Assured Business Income for Franchisee
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Franchise's earning opportunity is 2.75X on the investment by end of the agreement term
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Franchisee can add to the business by personal & professional networking.
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Legitimate & privileged business.
                </p>
                <p>
                  <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4738 1.66487C23.4738 1.66487 26.5507 1.0186 27.8473 0.0430923C27.8473 0.0430923 28.6562 1.3397 26.5507 4.09549L25.8353 4.81086C25.7784 4.86777 25.7662 4.95312 25.7987 5.02629L25.9004 5.22952C26.0629 5.55468 25.9004 7.66015 21.6894 9.44044L21.5187 9.61115C21.4212 9.7087 21.3683 9.83877 21.3683 9.97696V10.2493C21.3683 10.2493 15.2105 13.9765 9.54036 12.3548C9.54036 12.3548 8.76808 12.2166 8.56892 12.5173C8.24375 13.0051 5.81312 12.6799 5.81312 12.6799C5.81312 12.6799 2.41106 11.7085 0.951867 8.469C-0.503258 5.22545 0.143013 3.77033 0.143013 3.77033C0.143013 3.77033 0.955932 0.36826 4.52058 0.0430923C8.08523 -0.282075 15.0519 1.3397 15.0519 1.3397C15.0519 1.3397 20.3969 2.6363 23.4779 1.66487H23.4738Z" fill="#02714C"/>
                  </svg>

                  Day to Day operations, Stocks, Inventory, In-store merchandising, Utility, Staff salaries, etc paid/managed by Franchisee.
                </p>
                
            </div>
        
    </div>

    
</div>
  )
}

export default franchise_model_operated