import React from 'react'
import Internior from '../../assets/interior.png';
import Signboard from '../../assets/signboard.png';
import Kitchen from '../../assets/kitchen.png';
import Camera from '../../assets/camera.png';
import Software from '../../assets/software.png';
import Fee from '../../assets/fee.png';

function franchise_model() {
  return (
    <div className='franchise_model_container'>
        <div className='row'>
            <div className='model_heading'>
                <h4>BIGGUY’s WINGERY Franchise Model</h4>
                <h6>The accessories includes with this Investment</h6>
            </div>
        </div>

        <div className='row mobileDesign pt-5'>
            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Internior} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>1</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>Interiors & furnitures</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>


            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Signboard} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>2</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>Lighting, Display, Signboards, Visual Merchandising</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>


            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Kitchen} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>3</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>Kitchen equipment</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>

            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Camera} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>4</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>CCTV camera setup</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>


            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Software} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>5</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>Point of sale machine, software</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>


            <div className='col-sm-4'>
                <div className='info_card'>
                    <div className='info_image'>
                        <img src={Fee} />
                    </div>

                    <div className='row info_box'>
                        <div className='col-sm-4'>
                            <div className='info_num'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 158 158" fill="none">
                                    <g filter="url(#filter0_d_31_2321)">
                                    <circle cx="85.3722" cy="66.8703" r="54.8063" fill="#02714C"/>
                                    </g>
                                    <defs>
                                    <filter id="filter0_d_31_2321" x="0.940884" y="0.213951" width="157.013" height="157.013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="-5.92501" dy="11.85"/>
                                    <feGaussianBlur stdDeviation="11.85"/>
                                    <feComposite in2="hardAlpha" operator="out"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.443137 0 0 0 0 0.298039 0 0 0 0.6 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_2321"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_2321" result="shape"/>
                                    </filter>
                                    </defs>
                                </svg>
                                <p>6</p>
                            </div>
                        </div>
                        
                    
                        <div className='col-sm-8'>
                            <div className='franchise_info'>
                                <h3>Franchise fee (5Lacs for 5 year)</h3>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default franchise_model