

const renderMenuData = [
    {
        "name": "GRILLED ATOMIC CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/ATOMIC_CHICKEN_WINGS.png",
        "price": 229,
        "spicyLevel": 20,
        "id": 1,
        "description": "An American classic; spicy, sweet and smoky which will have you licking your fingers, served with smokey bacon",
        "u_id":1
        },
        {
        "name": "GRILLED ATOMIC CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 409,
        "spicyLevel": 20,
        "id": 1,
        "description": "An American classic; spicy, sweet and smoky which will have you licking your fingers, served with smokey bacon",
        "u_id":2
        },
        {
        "name": "GRILLED FIERY SICHUAN CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_FIERY_SICHUAN_CHICKEN_WINGS.png",
        "price": 229,
        "spicyLevel": 40,
        "id": 1,
        "description": "Perfectly grilled wings cooked to perfection enveloped in our chuncky sichuan sauce flavoured with ginger, garlic and whole red chillies.",
        "u_id":3
        },
        {
        "name": "GRILLED FIERY SICHUAN CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_FIERY_SICHUAN_CHICKEN_WINGS.png",
        "price": 429,
        "spicyLevel": 40,
        "id": 1,
        "description": "Perfectly grilled wings cooked to perfection enveloped in our chuncky sichuan sauce flavoured with ginger, garlic and whole red chillies.",
        "u_id":4
        },
        {
        "name": "GRILLED NASHVILLE HOT CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_NASHVILLE_HOT_CHICKEN_WINGS.png",
        "price": 239,
        "spicyLevel": 60,
        "id": 1,
        "description": "Relish our bold grilled chicken wings smothered in hot & smoky Nashville sauce.",
        "u_id":5
        },
        {
        "name": "GRILLED NASHVILLE HOT CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_NASHVILLE_HOT_CHICKEN_WINGS.png",
        "price": 439,
        "spicyLevel": 60,
        "id": 1,
        "description": "Relish our bold grilled chicken wings smothered in hot & smoky Nashville sauce.",
        "u_id":6
        },
        {
        "name": "GRILLED HICKORY SMOKED BBQ WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_HICKORY_SMOKED_BBQ_WINGS.png",
        "price": 249,
        "spicyLevel": 60,
        "id": 1,
        "description": "An American classic; spicy, sweet and smoky which will have you licking your fingers",
        "u_id":7
        },
        {
        "name": "GRILLED HICKORY SMOKED BBQ WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/GRILLED_HICKORY_SMOKED_BBQ_WINGS.png",
        "price": 449,
        "spicyLevel": 80,
        "id": 1,
        "description": "An American classic; spicy, sweet and smoky which will have you licking your fingers",
        "u_id":8
        },
        {
        "name": "PERI PERI CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/PERI_PERI_CHICKEN_WINGS.png",
        "price": 199,
        "spicyLevel": 80,
        "id": 1,
        "description": "The perfect fried chiken wings and spiced to percetion with Peri Peri seasoning",
        "u_id":9
        },
        {
        "name": "PERI PERI CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/PERI_PERI_CHICKEN_WINGS.png",
        "price": 369,
        "spicyLevel": 80,
        "id": 1,
        "description": "The perfect fried chiken wings and spiced to percetion with Peri Peri seasoning",
        "u_id":10
        },
        {
        "name": "CAJUN CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/CAJUN_CHICKEN_WINGS.png",
        "price": 199,
        "spicyLevel": 80,
        "id": 1,
        "description": "Zingy lemon and pepper salt sprinkled over chicken wings",
        "u_id":11
        },
        {
        "name": "CAJUN CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/CAJUN_CHICKEN_WINGS.png",
        "price": 369,
        "spicyLevel": 100,
        "id": 1,
        "description": "Zingy lemon and pepper salt sprinkled over chicken wings",
        "u_id":12
        },
        {
        "name": "FIERY SICHUAN CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/FIERY_SICHUAN_CHICKEN_WINGS.png",
        "price": 259,
        "spicyLevel": 100,
        "id": 1,
        "description": "From the orient, spicy and saucy schezwan glaze over freshly fried chicken wings",
        "u_id":13
        },
        {
        "name": "FIERY SICHUAN CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/FIERY_SICHUAN_CHICKEN_WINGS.png",
        "price": 489,
        "spicyLevel": 100,
        "id": 1,
        "description": "From the orient, spicy and saucy schezwan glaze over freshly fried chicken wings",
        "u_id":14
        },
        {
        "name": "NASHVILLE HOT CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/NASHVILL_HOT_CHICKEN_WINGS.png",
        "price": 259,
        "spicyLevel": 100,
        "id": 1,
        "description": "Experience Nashville's culinary heat in this extra-crispy fried chicken doused in a fiery cayenne glaze.",
        "u_id":15
        },
        {
        "name": "NASHVILLE HOT CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/NASHVILL_HOT_CHICKEN_WINGS.png",
        "price": 489,
        "spicyLevel": 100,
        "id": 1,
        "description": "Experience Nashville's culinary heat in this extra-crispy fried chicken doused in a fiery cayenne glaze.",
        "u_id":16
        },
        {
        "name": "ATOMIC CHICKEN WINGS - 4 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/ATOMIC_CHICKEN_WINGS.png",
        "price": 259,
        "spicyLevel": 0,
        "id": 1,
        "description": "Not for the faint hearted, falvourful and spicy hot sauce doused wings",
        "u_id":17
        },
        {
        "name": "ATOMIC CHICKEN WINGS - 8 Pcs",
        "category": "WINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/ATOMIC_CHICKEN_WINGS.png",
        "price": 489,
        "spicyLevel": 0,
        "id": 1,
        "description": "Not for the faint hearted, falvourful and spicy hot sauce doused wings",
        "u_id":18
        },
        {
        "name": "TARTAR DIP",
        "category": "DIPS",
        "imgUrl": "https://bigguyswingery.com/static/media/dips.png",
        "price": 30,
        "spicyLevel": null,
        "id": 4,
        "description": "An all time favourite dip for a cheesy experience",
        "u_id":19
        },
        {
        "name": "CHEESY DIP",
        "category": "DIPS",
        "imgUrl": "https://bigguyswingery.com/static/media/dips.png",
        "price": 30,
        "spicyLevel": null,
        "id": 4,
        "description": "Our version of garlicky toum sauce",
        "u_id":20
        },
        {
        "name": "FIERY SICHUAN DIP",
        "category": "DIPS",
        "imgUrl": "https://bigguyswingery.com/static/media/dips.png",
        "price": 30,
        "spicyLevel": null,
        "id": 4,
        "description": "Thick and creamy mayonnaise flavoured with Peri Peri",
        "u_id":21
        },
        {
        "name": "NASHVILLE HOT DIP",
        "category": "DIPS",
        "imgUrl": "https://bigguyswingery.com/static/media/dips.png",
        "price": 30,
        "spicyLevel": null,
        "id": 4,
        "description": "Dip made with dried red chiilies, garlic and giner",
        "u_id":22
        },
        {
        "name": "ATOMIC DIP",
        "category": "DIPS",
        "imgUrl": "https://bigguyswingery.com/static/media/dips.png",
        "price": 30,
        "spicyLevel": null,
        "id": 4,
        "description": "Our version of hot and sweet buffalo hot sauce",
        "u_id":23
        },
        {
        "name": "THE OG CHICKEN BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/AMERICAN_CHEESE_CHICKEN_BURGER.png",
        "price": 239,
        "spicyLevel": null,
        "id": 5,
        "description": "",
        "u_id":24
        },
        {
        "name": "AMERICAN CHEESE CHICKEN BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/AMERICAN_CHEESE_CHICKEN_BURGER.png",
        "price": 219,
        "spicyLevel": null,
        "id": 5,
        "description": "",
        "u_id":25
        },
        {
        "name": "CHICKEN CLUCKINATOR BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/AMERICAN_CHEESE_CHICKEN_BURGER.png",
        "price": 229,
        "spicyLevel": null,
        "id": 5,
        "description": "",
        "u_id":26
        },
        {
        "name": "NASHVILLE HOT CHICKEN BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/NASHVILLE_HOT_CHICKEN_BURGER.png",
        "price": 219,
        "spicyLevel": null,
        "id": 5,
        "description": "It really doesn’t get much better than crispy and juicy fried chicken on a toasted bun with a smoky Nashville sauce.",
        "u_id":27
        },
        {
        "name": "ATOMIC FRIED CHICKEN BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/ATOMIC_FRIED_CHICKEN_BURGER.png",
        "price": 229,
        "spicyLevel": null,
        "id": 5,
        "description": "As you'd expect, El Diablo is fried chicken with a major kick. The chicken gets a hefty dose of spice thanks to our signature hot sauce.",
        "u_id":28
        },
        {
        "name": "LOUISIANA CRUCHY CHICKEN BURGER",
        "category": "BURGERS",
        "imgUrl": "https://bigguyswingery.com/static/media/LOUISIANA_CRUCHY_CHICKEN_BURGER.png",
        "price": 209,
        "spicyLevel": null,
        "id": 5,
        "description": "Hand-breaded fried chicken on a warm toasted bun with lettuce, gherkins , toum and American cheese.",
        "u_id":29
        },
        {
        "name": "PERI PERI CRINKLE FRIES LARGE",
        "category": "FRIES",
        "imgUrl": "https://bigguyswingery.com/static/media/PERI_PERI_CRINKLE_FRIES_LARGE.png",
        "price": 149,
        "spicyLevel": null,
        "id": 6,
        "description": "Crinkle fries fried to perfection flavoured with Peri Peri seasoning",
        "u_id":30
        },
        {
        "name": "CAJUN CRINKLE FRIES LARGE",
        "category": "FRIES",
        "imgUrl": "https://bigguyswingery.com/static/media/CAJUN_CRINKLE_FRIES_LARGE.png",
        "price": 149,
        "spicyLevel": null,
        "id": 6,
        "description": "",
        "u_id":31
        },
        {
        "name": "LOADED ANIMAL FRIES",
        "category": "FRIES",
        "imgUrl": "https://bigguyswingery.com/static/media/LOADED_ANIMAL_FRIES.png",
        "price": 199,
        "spicyLevel": null,
        "id": 6,
        "description": "Salted crinkle cut fries topped with luxrious cheese sauce",
        "u_id":32
        },
        {
        "name": "COLD COFFEE",
        "category": "BEVERAGES",
        "imgUrl": "https://bigguyswingery.com/static/media/COLD_COFFEE.png",
        "price": 149,
        "spicyLevel": null,
        "id": 7,
        "description": "",
        "u_id":33
        },
        {
        "name": "CHOCOLATE OREO SHAKE",
        "category": "BEVERAGES",
        "imgUrl": "https://bigguyswingery.com/static/media/CHOCOLATE_OREO_SHAKE.png",
        "price": 169,
        "spicyLevel": null,
        "id": 7,
        "description": "Krunchy Oero Biscuits Blended With Milk & Ice Cream. This Is A Crowd Favourite.",
        "u_id":34
        },
        {
        "name": "PERI PERI CHICKEN CLUCKETS 3 PCS",
        "category": "CLUCKETS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 169,
        "spicyLevel": null,
        "id": 8,
        "description": "Golden fried hand breaded crispy & juicy chicken tenders seasoned with Peri Peri, served with Tartar Dip",
        "u_id":35
        },
        {
        "name": "PERI PERI CHICKEN CLUCKETS 6 PCS",
        "category": "CLUCKETS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 289,
        "spicyLevel": null,
        "id": 8,
        "description": "",
        "u_id":36
        },
        {
        "name": "CAJUN CHICKEN CLUCKETS 3 PCS",
        "category": "CLUCKETS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 169,
        "spicyLevel": null,
        "id": 8,
        "description": "In house breaded chicken tenders, fried to perfection & seasoned with Cajun seasoning. Served with Tartar dip",
        "u_id":37
        },
        {
        "name": "CAJUN CHICKEN CLUCKETS 6 PCS",
        "category": "CLUCKETS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 289,
        "spicyLevel": null,
        "id": 8,
        "description": "",
        "u_id":38
        },
        {
        "name": "CRISPY PERI PERI CALAMARI",
        "category": "RINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 0,
        "spicyLevel": null,
        "id": 9,
        "description": "",
        "u_id":39
        },
        {
        "name": "CRISPY CAJUN CALAMARI",
        "category": "RINGS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 0,
        "spicyLevel": null,
        "id": 9,
        "description": "",
        "u_id":40
        },
        {
        "name": "LOUISIANA CRUNCHY CHICKEN BURGER COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/LOUISIANA_CRUNCHY_CHICKEN_BURGER_COMBO.png",
        "price": 379,
        "spicyLevel": null,
        "id": 2,
        "description": "An epic combo with our signature crunchy Louisiana chicken burger, 2 Pc peri peri wings and aerated beverage.",
        "u_id":41
        },
        {
        "name": "AMERICAN CHEESE CHICKEN BURGER, WINGS & FRIES COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/AMERICAN_CHEESE_CHICKEN_BURGER.png",
        "price": 349,
        "spicyLevel": null,
        "id": 2,
        "description": "American cheese chicken Burger with 2 Pc peri peri wings and fries",
        "u_id":42
        },
        {
        "name": "ULTIMATE WINGS COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/grilled_automic_chicken_wings.png",
        "price": 469,
        "spicyLevel": null,
        "id": 2,
        "description": "8 Pc PERI PERI CHICKEN wings, FRIES and PEPSI",
        "u_id":43
        },
        {
        "name": "WINGS PARTY COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/WINGS_PARTY_COMBO.png",
        "price": 629,
        "spicyLevel": null,
        "id": 2,
        "description": "Ideal for a party which includes 4 pc cajun wings, 4 pc hickory smoked bbq wings & 4 pc nashville hot chicken wings",
        "u_id":44
        },
        {
        "name": "WINGS SNACKER COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/WINGS_SNACKER_COMBO.png",
        "price": 209,
        "spicyLevel": null,
        "id": 2,
        "description": "A perfect snack with 3 pc fried peri peri chicken wings served with inhouse dip and pepsi",
        "u_id":45
        },
        {
        "name": "DUAL WINGS COMBO",
        "category": "COMBOS",
        "imgUrl": "https://bigguyswingery.com/static/media/DUAL_WINGS_COMBO.png",
        "price": 229,
        "spicyLevel": null,
        "id": 2,
        "description": "A mini snack consisting of 2 pc BBQ grilled chicken wings and 2pc peri peri fried chicken wings with special dip",
        "u_id":46
        }

];

export default renderMenuData;