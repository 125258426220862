import React from 'react'
import HomepageSlider from './HomepageSlider'
import HomepageAbout from './HomepageAbout'
import HomepageCta from './HomepageCta'
import OfferSection from './HomepageOfferSection'
import WhatWeHave from './what_we_have'

import OfferFood from '../../assets/WET-BUFFALO-HOT-CHICKEN-WINGS.png';
import subOfferFood from '../../assets/subOffer.png';
import subOffer2 from '../../assets/subOffer1.png';
import FooterImage from '../../footer_top_image';
import FooterImg from '../../assets/footerimg.png';
import HomepageBanner from '../../assets/banner3.jpg';
import HomepageBanner2 from '../../assets/banner2.jpg';
import MHomepageBanner from '../../assets/Mbanner3.png';
import MHomepageBanner2 from '../../assets/Mbanner2.png';


function Homepage() {
  return (
    <>
        <HomepageSlider banneImage = {HomepageBanner} banneImage2 = {HomepageBanner2} MbanneImage ={MHomepageBanner} MbanneImage2={MHomepageBanner2} />
        <OfferSection OfferFood = {OfferFood} subOfferFood = {subOfferFood} subOffer2 = {subOffer2} />
        <WhatWeHave />
        <HomepageCta />
        <HomepageAbout />
        <FooterImage dfimage={FooterImg} />

    </>
  )
}

export default Homepage