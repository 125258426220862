import React from 'react'

function FranchiseInfo() {
  return (
    <div className='fInfo shadow'>
        <div className='row'>
            <div className='col-sm-8'>
                <div className='fInfo_content'>
                    <h2>Total Investment of </h2>
                    <p>Only ₹16L to ₹18L</p>
                </div>
            </div>
            <div className='col-sm-4 d-flex justify-content-center align-content-center'>
                <a href='#' className='fInfoBtn'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="64" viewBox="0 0 128 64" fill="none">
                        <rect width="128" height="64" rx="32" fill="#1D1D1B"/>
                        <path d="M45 30C43.8954 30 43 30.8954 43 32C43 33.1046 43.8954 34 45 34L45 30ZM84.4142 33.4142C85.1953 32.6332 85.1953 31.3668 84.4142 30.5858L71.6863 17.8579C70.9052 17.0768 69.6389 17.0768 68.8579 17.8579C68.0768 18.6389 68.0768 19.9052 68.8579 20.6863L80.1716 32L68.8579 43.3137C68.0768 44.0948 68.0768 45.3611 68.8579 46.1421C69.6389 46.9232 70.9052 46.9232 71.6863 46.1421L84.4142 33.4142ZM45 34L83 34L83 30L45 30L45 34Z" fill="white"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
  )
}

export default FranchiseInfo