import './App.css';
import Homepage from './components/Homepage/Homepage';
import Menu from './components/Menu/Menu';
import About from './components/About/About';
import Franchise from './components/Franchise/franchise';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Logo from './assets/NavbarLogo.png'



import {Routes, Route} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Navbar logo = {Logo} />

      <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='about' element={<About />} />
          <Route path='menu' element={<Menu />} />
          <Route path='franchise' element={<Franchise />} />
        </Routes>


        <Footer logo={Logo} />

    </div>
  );
}

export default App;
