import React from 'react'
import Banner from  './Banner'
import FranchiseInfo from  './FranchiseInfo'
import AboutFranchise from  './about_franchise'
import AboutDescription from './about_description'
import FranchiseModel from './franchise_model'
import ContactForm from './ContactForm'
import FranchiseModelOperated from './franchise_model_operated'
import FooterImg from '../../assets/footerimg.png';
import FooterImage from '../../footer_top_image';


function franchise() {
  return (
    <>
    <Banner />
    <FranchiseInfo />
    <FranchiseModel />
    <FranchiseModelOperated />
      <AboutFranchise />
      <AboutDescription />
      
      
      <ContactForm />
      <FooterImage dfimage={FooterImg} />

    </>
  )
}

export default franchise