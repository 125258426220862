import React, {useState} from 'react'
import {
    Link
  } from "react-router-dom";


function Navbar(props) {

    const mobileMenu = [
        {
            "id":"menu_item1"
        },
        {
            "id":"menu_item2"
        },
        {
            "id":"menu_item3"
        },
        {
            "id":"menu_item4"
        }
    ]
    const [menuId, setMenuId ] = useState(mobileMenu);



    
    
    const menuItem = (x) => {

        setMenuId(x)

    }


    
  return (
    <>
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link to="/">
                    <a className="navbar-brand" href="#">
                        <img src= {props.logo} />
                    </a>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <Link to="/">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" data-page="Home" href="#">HOME</a>
                        </li>
                    </Link>
                    <Link to="/menu">
                        <li className="nav-item">
                            <a className="nav-link" href="#" data-page="Menu">MENU</a>
                        </li>
                    </Link>
                    <Link to="/franchise">
                        <li className="nav-item">
                            <a className="nav-link" href="#" data-page="Franchise">OWN A FRANCHISE</a>
                        </li>
                    </Link>
                    
                </ul>
                <span className="navbar-text">
                <Link to="/about">
                    <a href='#' data-page="About">ABOUT US</a>
                </Link>
                </span>
                </div>
            </div>
            
            </nav>
            <div className='mobile_menu'>
            
                <div className='menu_list' >
                <Link to="/">
                    <div id='menu_item1' className={'menu_links ' + ((menuId === "menu_item1") ? "active_menuLink" : "" )} onClick={() => menuItem("menu_item1")}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.13472 18.7733V15.7156C7.13472 14.9351 7.77211 14.3023 8.55837 14.3023H11.4325C11.8101 14.3023 12.1722 14.4512 12.4392 14.7163C12.7062 14.9813 12.8562 15.3408 12.8562 15.7156V18.7733C12.8538 19.0979 12.982 19.4099 13.2123 19.6402C13.4426 19.8705 13.756 20 14.0829 20H16.0438C16.9596 20.0024 17.8387 19.6429 18.4871 19.0008C19.1355 18.3588 19.4999 17.487 19.4999 16.5778V7.86687C19.4999 7.13247 19.172 6.43585 18.6046 5.96468L11.934 0.675877C10.7736 -0.251431 9.11105 -0.22149 7.98533 0.746987L1.46695 5.96468C0.87268 6.42196 0.517491 7.12064 0.499939 7.86687V16.5689C0.499939 18.4639 2.04732 20 3.95611 20L5.87223 20C6.55117 20 7.10294 19.4562 7.10786 18.7822L7.13472 18.7733Z" fill="#1D1D1B"/>
                        </svg>
                        <p>Home</p>
                    </div>
                    
                    </Link>
                </div>
                
                <div className='menu_list'>
                <Link to="/menu">
                    <div  id='menu_item2' className={'menu_links ' + ((menuId === "menu_item2") ? "active_menuLink" : "" )} onClick={() => menuItem("menu_item2")} >
                        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="3.09677" rx="1.54839" fill="#1D1D1B"/>
                            <rect y="6.19355" width="24" height="3.09677" rx="1.54839" fill="#1D1D1B"/>
                            <rect y="12.3867" width="24" height="3.09677" rx="1.54839" fill="#1D1D1B"/>
                            <path d="M6.67219 10.8867C6.28729 11.5534 5.32504 11.5534 4.94014 10.8867L3.65507 8.66091C3.27017 7.99425 3.7513 7.16091 4.5211 7.16091L7.09124 7.16091C7.86104 7.16091 8.34216 7.99425 7.95726 8.66091L6.67219 10.8867Z" fill="#1D1D1B"/>
                        </svg>

                        <p>Menu</p>
                    </div>
                    </Link>
                </div>
                <div className='menu_list'>
                <Link to="/franchise">
                    <div  id='menu_item3' className={'menu_links ' + ((menuId === "menu_item3") ? "active_menuLink" : "" )} onClick={() => menuItem("menu_item3")} >
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.9 0H11.07C13.78 0 15.97 1.07 16 3.79V18.97C16 19.14 15.96 19.31 15.88 19.46C15.75 19.7 15.53 19.88 15.26 19.96C15 20.04 14.71 20 14.47 19.86L7.99 16.62L1.5 19.86C1.351 19.939 1.18 19.99 1.01 19.99C0.45 19.99 0 19.53 0 18.97V3.79C0 1.07 2.2 0 4.9 0ZM4.22 7.62H11.75C12.18 7.62 12.53 7.269 12.53 6.83C12.53 6.39 12.18 6.04 11.75 6.04H4.22C3.79 6.04 3.44 6.39 3.44 6.83C3.44 7.269 3.79 7.62 4.22 7.62Z" fill="#02714C"/>
                        </svg>

                        <p>Franchise</p>
                    </div>
                    </Link>
                </div>
                <div className='menu_list'>
                <Link to="/about">
                    <div  id='menu_item4' className={'menu_links ' + ((menuId === "menu_item4") ? "active_menuLink" : "" )} onClick={() => menuItem("menu_item4")} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
                        <path d="M9.49603 15.2164C14.4941 15.2164 18.712 16.0286 18.712 19.1621C18.712 22.2967 14.4665 23.08 9.49603 23.08C4.49908 23.08 0.280029 22.2679 0.280029 19.1344C0.280029 15.9998 4.52559 15.2164 9.49603 15.2164ZM9.49603 0.0400391C12.8819 0.0400391 15.5947 2.75187 15.5947 6.13533C15.5947 9.51879 12.8819 12.2318 9.49603 12.2318C6.11134 12.2318 3.39736 9.51879 3.39736 6.13533C3.39736 2.75187 6.11134 0.0400391 9.49603 0.0400391Z" fill="black"/>
                    </svg>
                        <p> About</p>
                    </div>
                    </Link>
                </div>
            </div>
    </>
  )
}

export default Navbar