import React from "react";

import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';


function AboutHeroSection() {
  return (
    <div className=" about_container_inner1">
      <div className="about_cont1">
        <div className="about_img_cont">
            <div className="aboutImgWrapper">
                <img src={image1} />
            </div>
          
        </div>
        <div className="about_text_cont">
            <div className="aboutTextWrapper">
                <h1>
                    <span>More</span>
                    <span>about us.</span>
                </h1>
            <p>
                The standard chunk of Lorem Ipsum used since the 1500s is reproduced
                below for those interested.
            </p>
            </div>
          
        </div>
      </div>
      <div className="about_cont2">
        <img src={image2} />
      </div>
    </div>
  );
}

export default AboutHeroSection;
