import React from 'react'
import FranchiseBanner from '../../assets/franchise_banner.png';


function Banner() {
  return (
    <>
    <section className='franchiseBanner'>
        <div className='fbannerWrapper'>
            <img src={FranchiseBanner} className="fdesktopImg" />
            <img src={FranchiseBanner} className="fmobileImg" />
        </div>
    </section>
    </>
  )
}

export default Banner