import React from 'react';

const what_we_have_card = (props) => {
  return (
    <div className='catagory_card'>
        <div className='cata_img'>
            <img src={props.img}/>
        </div>
        <div className='cata_details'>
            <h5>{props.name}</h5>
            <span>
                <p>{props.price}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="32" viewBox="0 0 57 32" fill="none">
                <rect x="0.359009" y="0.975098" width="56.499" height="30.8176" rx="15.4088" fill="#1D1D1B"/>
                <path d="M18.8494 15.1163C18.4239 15.1163 18.0789 15.4612 18.0789 15.8867C18.0789 16.3122 18.4239 16.6572 18.8494 16.6572L18.8494 15.1163ZM37.8847 16.4315C38.1856 16.1306 38.1856 15.6428 37.8847 15.3419L32.9817 10.4389C32.6808 10.138 32.193 10.138 31.8921 10.4389C31.5912 10.7398 31.5912 11.2276 31.8921 11.5284L36.2504 15.8867L31.8921 20.245C31.5912 20.5459 31.5912 21.0337 31.8921 21.3346C32.193 21.6354 32.6808 21.6354 32.9817 21.3346L37.8847 16.4315ZM18.8494 16.6572L37.34 16.6572L37.34 15.1163L18.8494 15.1163L18.8494 16.6572Z" fill="white"/>
                </svg>
            </span>
        </div>
    </div>
  )
}

export default what_we_have_card;