import React, { useState } from "react";
import icon1 from '../../assets/icon1.png'
import {
  Link
} from "react-router-dom";

function Slider() {
  const SPICY_LABEL_DATA = [
    {
      id: 0,
      title: "Hickory Smoked",
      icon:'<svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none"><path d="M8.64283 23.3213C10.8418 22.9469 13.0059 22.3809 15.1091 21.6319C16.9944 20.9439 18.7624 19.9642 20.3387 18.7233C23.195 14.0294 21.1572 10.1496 19.925 8.46908C19.3547 10.4545 17.5389 11.8219 15.4749 11.8219C14.2079 11.8176 12.993 11.3036 12.1046 10.398C11.2598 9.54018 10.8071 8.37752 10.8418 7.17584C10.9072 4.45867 12.0523 1.87657 14.0205 0C5.00289 2.29025 2.51672 9.662 2.51672 13.9426C2.51672 20.1257 7.5413 22.8209 8.64306 23.3215L8.64283 23.3213ZM7.54979 7.17564C7.66292 7.05803 7.82407 7.00147 7.98522 7.01449C8.28574 7.04054 8.50773 7.30627 8.47721 7.60657C8.41617 8.78223 8.57732 9.96216 8.9519 11.0812C9.73139 13.3279 11.351 14.5864 13.7546 14.8302V14.8214C14.0552 14.852 14.2772 15.1175 14.2466 15.418C14.2161 15.7185 13.9506 15.9405 13.6501 15.91C8.93014 15.4444 7.75016 11.6432 7.47596 9.31389C6.77052 10.8945 6.42218 12.6144 6.46572 14.3429C6.46572 17.3169 8.08108 18.8932 9.83603 20.1558C10.0798 20.3342 10.1366 20.674 9.95791 20.9177C9.77946 21.1615 9.43968 21.2183 9.19594 21.0396C7.30608 19.6768 5.37294 17.8132 5.37294 14.343C5.37294 9.42268 7.46299 7.26719 7.55007 7.17563L7.54979 7.17564Z" fill="#02714C"/><path d="M27.1834 18.4792C25.0758 16.3716 24.2443 17.038 22.0235 18.8145C21.7317 19.0322 21.4181 19.2847 21.083 19.5417C19.3979 20.874 17.5125 21.9322 15.4966 22.6767C11.1336 24.253 6.9319 24.7493 4.14517 25.0628C2.79534 25.224 1.27563 25.4024 0.875183 25.6289C0.927474 25.7725 1.01883 25.8989 1.13644 25.9903C2.81709 27.475 9.91913 28.1544 14.9134 27.7495C18.7844 27.4359 26.953 24.732 28.1593 21.4619H28.155C28.5247 20.4604 28.2157 19.4893 27.1836 18.4792L27.1834 18.4792Z" fill="#02714C"/></svg>',
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
    {
      id: 10,
      title: "Cajun",
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
    {
      id: 20,
      title: "Nashville Hot",
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
    {
      id: 30,
      title: "Peri Peri",
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
    {
      id: 40,
      title: "Fiery Sichuan",
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
    {
      id: 50,
      title: "Atomic",
      description: "Bold, Smoky, Sweet and rich, this barbecue sauce has got it all. It’s all the flavours of a summer night distilled into one.",
    },
  ];

  const [items, setItems] = useState(SPICY_LABEL_DATA);

  function getChangedVal(e) {
    let val = e.target.value;

    const filterItems = SPICY_LABEL_DATA.filter((item) => val == item.id);
    document.querySelector(".rangeDataTitle").innerText = filterItems[0].title;
    document.querySelector(".rangeDataDescription").innerText =
      filterItems[0].description;
  }

  return (
    <div className="spicySliderWrapper">

      <h2 className="spicyHeading">Spicy Flavours</h2>
      <p className="spicyDescription">Introducing our top best saucy or dry rub flavours that range from mild to hot, in sweet or savory.</p>
      
      

      <div className="rangeBox">
        <div className="row">
          <div className="col-md-3">
            <div className="spicyIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none"><path d="M8.64283 23.3213C10.8418 22.9469 13.0059 22.3809 15.1091 21.6319C16.9944 20.9439 18.7624 19.9642 20.3387 18.7233C23.195 14.0294 21.1572 10.1496 19.925 8.46908C19.3547 10.4545 17.5389 11.8219 15.4749 11.8219C14.2079 11.8176 12.993 11.3036 12.1046 10.398C11.2598 9.54018 10.8071 8.37752 10.8418 7.17584C10.9072 4.45867 12.0523 1.87657 14.0205 0C5.00289 2.29025 2.51672 9.662 2.51672 13.9426C2.51672 20.1257 7.5413 22.8209 8.64306 23.3215L8.64283 23.3213ZM7.54979 7.17564C7.66292 7.05803 7.82407 7.00147 7.98522 7.01449C8.28574 7.04054 8.50773 7.30627 8.47721 7.60657C8.41617 8.78223 8.57732 9.96216 8.9519 11.0812C9.73139 13.3279 11.351 14.5864 13.7546 14.8302V14.8214C14.0552 14.852 14.2772 15.1175 14.2466 15.418C14.2161 15.7185 13.9506 15.9405 13.6501 15.91C8.93014 15.4444 7.75016 11.6432 7.47596 9.31389C6.77052 10.8945 6.42218 12.6144 6.46572 14.3429C6.46572 17.3169 8.08108 18.8932 9.83603 20.1558C10.0798 20.3342 10.1366 20.674 9.95791 20.9177C9.77946 21.1615 9.43968 21.2183 9.19594 21.0396C7.30608 19.6768 5.37294 17.8132 5.37294 14.343C5.37294 9.42268 7.46299 7.26719 7.55007 7.17563L7.54979 7.17564Z" fill="#02714C"/><path d="M27.1834 18.4792C25.0758 16.3716 24.2443 17.038 22.0235 18.8145C21.7317 19.0322 21.4181 19.2847 21.083 19.5417C19.3979 20.874 17.5125 21.9322 15.4966 22.6767C11.1336 24.253 6.9319 24.7493 4.14517 25.0628C2.79534 25.224 1.27563 25.4024 0.875183 25.6289C0.927474 25.7725 1.01883 25.8989 1.13644 25.9903C2.81709 27.475 9.91913 28.1544 14.9134 27.7495C18.7844 27.4359 26.953 24.732 28.1593 21.4619H28.155C28.5247 20.4604 28.2157 19.4893 27.1836 18.4792L27.1834 18.4792Z" fill="#02714C"/></svg>
            </div>
          </div>
          <div className="col-md-9">
            <input
              type="range"
              className="form-range customSlider"
              min="0"
              defaultValue={0}
              max="50"
              step="10"
              onChange={getChangedVal}
            />
          </div>
        </div>
        

        <div className="rangeInfo">
          <div className="rangeIcon"></div>

          <h2 className="rangeDataTitle">{SPICY_LABEL_DATA[0].title}</h2>
          <p className="rangeDataDescription">
            {SPICY_LABEL_DATA[0].description}
          </p>

        </div>
      </div>

      <Link to="/menu">
      <a className="btn spicyBtn">
            browse menu
            <svg
              width="43"
              height="25"
              viewBox="0 0 53 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="52.9843" height="30" rx="15" fill="#1D1D1B" />
              <path
                d="M18.3037 14.25C17.8895 14.25 17.5537 14.5858 17.5537 15C17.5537 15.4142 17.8895 15.75 18.3037 15.75L18.3037 14.25ZM36.1744 15.5303C36.4672 15.2374 36.4672 14.7626 36.1744 14.4697L31.4014 9.6967C31.1085 9.40381 30.6336 9.40381 30.3407 9.6967C30.0478 9.98959 30.0478 10.4645 30.3407 10.7574L34.5834 15L30.3407 19.2426C30.0478 19.5355 30.0478 20.0104 30.3407 20.3033C30.6336 20.5962 31.1085 20.5962 31.4014 20.3033L36.1744 15.5303ZM18.3037 15.75L35.644 15.75L35.644 14.25L18.3037 14.25L18.3037 15.75Z"
                fill="white"
              />
            </svg>
          </a>
          </Link>
    </div>
  );
}

export default Slider;
