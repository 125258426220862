import React, {useState} from 'react'
import UpdatedMenu from "./renderMenuData"
import Combo from '../../assets/combo.png';
import Wings from '../../assets/wings.png';
import Burger from '../../assets/burger.png';
import Cluckets from '../../assets/cluckets.png';
import Fries from '../../assets/fries.png';
import Dips from '../../assets/dips.png';
import Shakes from '../../assets/shake.png';



function MenuProducts() {

    const [items, setItems] = useState(UpdatedMenu);
    const [activePopup, setShow] = useState();

    const [activeId, setActiveId] = useState();



    
    
    const filterItem = (x) => {

      setActiveId(x)

      setItems(UpdatedMenu.filter((item) => x == item.id))

      console.log('first-> ', items)

    }

    const getData =( description ) =>{
console.log(description)
setShow(description)

    }


  return (
    <>
    <div className='category_row'>
     
        <div className= {'category_column ' + ((activeId === 2) ? "active" : "" )} onClick={() => filterItem(2)} id="2">
          <div className='category_img'>
            <img src={Combo} />
          </div>
          

          <h6>Combo</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 1) ? "active" : "" )} onClick={() => filterItem(1)} id="1">
        <div className='category_img'>
          <img src={Wings} />
          </div>

          <h6>Wings</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 5) ? "active" : "" )} onClick={() => filterItem(5)} id="5">
        <div className='category_img'>
          <img src={Burger} />
          </div>

          <h6>Burger</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 8) ? "active" : "" )} onClick={() => filterItem(8)} id="8">
        <div className='category_img'>
          <img src={Cluckets} />
          </div>

          <h6>Cluckets</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 6) ? "active" : "" )} onClick={() => filterItem(6)} id="6">
        <div className='category_img'>
          <img src={Fries} />
          </div>

          <h6>Fries</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 4) ? "active" : "" )} onClick={() => filterItem(4)} id="4">
        <div className='category_img'>
          <img src={Dips} />
          </div>

          <h6>Dips</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
        <div className={'category_column ' + ((activeId === 7) ? "active" : "" )} onClick={() => filterItem(7)} id="7"   >
        <div className='category_img'>
          <img src={Shakes} />
          </div>

          <h6>Shakes</h6>
     
          <div className='category_icon'>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 2.6665C23.3467 2.6665 29.3334 8.63984 29.3334 15.9998C29.3334 23.3465 23.3467 29.3332 16.0001 29.3332C8.64008 29.3332 2.66675 23.3465 2.66675 15.9998C2.66675 8.63984 8.64008 2.6665 16.0001 2.6665ZM12.0801 13.3732C11.6934 12.9732 11.0534 12.9732 10.6667 13.3598C10.2667 13.7598 10.2667 14.3865 10.6667 14.7732L15.2934 19.4265C15.4801 19.6132 15.7334 19.7198 16.0001 19.7198C16.2667 19.7198 16.5201 19.6132 16.7067 19.4265L21.3334 14.7732C21.5334 14.5865 21.6267 14.3332 21.6267 14.0798C21.6267 13.8132 21.5334 13.5598 21.3334 13.3598C20.9334 12.9732 20.3067 12.9732 19.9201 13.3732L16.0001 17.3065L12.0801 13.3732Z" fill="#1D1D1B"/>
            </svg>
          </div>
        </div>
     </div>


    <div className='products_row'>
      {items.map(item => (
        <div className={"product_column getVal_" + item.id + ((item.spicyLevel > 0) ? '  spicy_'+ item.spicyLevel : ' spicy_0') }  data-id={item.id} data-spicy={item.spicyLevel}>
          <div className='product_img'>
            <img src={item.imgUrl} />
          </div>
          <div className='product_info'>
            <div className='product_content'>
              <h6>{item.name}</h6>
              {/* <p>{item.description}</p> */}
            </div>
            <div className='product_price'>
              <p>₹{item.price}</p>

              <div className='product_btn' onClick={()=> getData(item.u_id)}>
                <a data-price={item.price} data-heading={item.name} data-description={item.description} data-image="https://placehold.jp/200x200.png">
                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
<path d="M31 0C48.112 0 62 13.919 62 31L61.9881 31.8673C61.5283 48.5788 47.822 62 31 62C13.919 62 0 48.112 0 31C0 13.919 13.919 0 31 0ZM31 22.351C30.38 22.351 29.791 22.599 29.357 23.033L18.6 33.852C18.135 34.286 17.918 34.875 17.918 35.464C17.918 36.084 18.135 36.673 18.6 37.138C19.53 38.037 20.987 38.037 21.886 37.107L31 27.962L40.114 37.107C41.013 38.037 42.501 38.037 43.4 37.138C44.33 36.208 44.33 34.751 43.4 33.852L32.643 23.033C32.209 22.599 31.62 22.351 31 22.351Z" fill="black" fill-opacity="0.35"/>
</svg>
                </a>
              </div>
              
            </div>
           
           
              <div className={'popup '+ ((activePopup === item.u_id) ? "popupShow" : "" )}><p>{item.description}</p></div>
           

            {/* {
            show === true ? <div><p>{item.description}</p></div> : ""
          } */}

            
          </div>
          

          
        </div>
      ))}
   
    </div>




  </>
  )
}

export default MenuProducts;