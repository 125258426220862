import React from 'react'
import '../../assets/menu_page.css';
import HomepageSlider from '../Homepage/HomepageSlider'
import HomepageCta from '../Homepage/HomepageCta'
import MenuProducts from './MenuProducts'
import HomepageBanner from '../../assets/banner3.jpg';
import HomepageBanner2 from '../../assets/banner1.jpg';
import MHomepageBanner from '../../assets/Mbanner3.png';
import MHomepageBanner2 from '../../assets/Mbanner1.png';
function Menu() {
  return (
    <>
       <HomepageSlider banneImage = {HomepageBanner} banneImage2 = {HomepageBanner2} MbanneImage ={MHomepageBanner} MbanneImage2={MHomepageBanner2} />
        <MenuProducts />
        <HomepageCta />
    </>
  )
}

export default Menu